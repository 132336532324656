@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,500;0,600;0,800;1,300&display=swap');
@import '~react-toastify/dist/ReactToastify.min.css';

:root {
  --primary: #00000076;
  --secondary: #e84137;
}

body,
html {
  overflow-x: hidden;
  /* Desactiva el scroll horizontal */
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}


body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}


.mySwiper {
  height: 100%;
  padding: 10px 0;
}




.mySwiper3 {
  height: 30rem;
  width: 100%;
  display: flex;
  z-index: 40;

}

.swiper-slide3 {
  height: 20rem;
  background-color: transparent;
}


input[type=range]::-webkit-slider-thumb {
  height: 2px;
  width: 5rem;
  border-radius: 7px;
  background: #ffffff;

}

/* media para pantallas de telefono */

@media (min-width: 500px) {




  * {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
  }

  *::-webkit-scrollbar {
    width: 15px;
  }

  *::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 5px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 5px;
  }


}









@media screen and (max-width: 500px) {
  .mySwiper {
    height: 100%;
    width: 100%;
    display: flex;

  }

  .mySwiper3 {
    height: 27rem;
    width: 100%;
    display: flex;

  }
}

.modalCard > div > div{
  border-radius: 41px;
}
.modalCardSmall > div > div {
  border-radius: 41px;
  
}
.modalCardSmall > div  {
  display: flex;
  justify-content: center;
  align-items: center;
  
}